import React, { useEffect, useState } from "react"
import styles from "./footer-menu.module.scss"
import { Link } from "gatsby"
import FooterLinks from "./footer-links"
import FooterSocial from "./footer-social"
import { withLanguage } from "../../../../i18n/with-language"
import Translate from "../../../../i18n/Translate"
import { changeableLink, isPage } from "../definition-page"

const activeLinkColor = '#319DED'

const FooterMenuComponent = ({ lang }) => {

  const [location, setLocation] = useState();

  useEffect(() => {
    setLocation(isPage())
  });

  return (
    <div className={`${styles.footer_menu} ${location ? styles.footerFeatureMenu : ''}`}>
      {lang === "ru" ? <>
        <div className={styles.column}>
          <div className={styles.list_wrapper}>
            <div className={styles.col}>
              <div className={styles.wrap}>
                <div className={styles.title}>Направления</div>
                <ul className={styles.list}>
                  <li><Link to='/landing/education/' activeStyle={{ color: activeLinkColor }}>Образование</Link></li>
                  <li><Link to='/landing/sports/' activeStyle={{ color: activeLinkColor }}>Спорт</Link></li>
                  <li><Link to='/landing/creativeschool/' activeStyle={{ color: activeLinkColor }}>Танцы</Link></li>
                  <li><Link to='/landing/crm-dlya-muzykalnoj-shkoly/' activeStyle={{ color: activeLinkColor }}>Музыка</Link></li>
                  <li><Link to='/landing/crm-dlja-tvorcheskih-shkol/' activeStyle={{ color: activeLinkColor }}>Творчество</Link></li>
                  <li><Link to='/landing/language/' activeStyle={{ color: activeLinkColor }}>Язык</Link></li>
                  <li><Link to='/landing/crm-dlya-repetitora/' activeStyle={{ color: activeLinkColor }}>Репетиторство</Link></li>
                  <li><Link to='/landing/beauty/' activeStyle={{ color: activeLinkColor }}>Beauty бизнес</Link></li>
                </ul>
              </div>
              {location ?
                <div className={styles.wrap}>
                  <div className={styles.title}>Возможности</div>
                  <div className={styles.lists}>
                    <ul className={styles.list}>
                      <li><Link to='/feature/clients/' activeStyle={{ color: activeLinkColor }}>Учет клиентов</Link></li>
                      <li><Link to={`/feature/${changeableLink('subscriptions')}/`} activeStyle={{ color: activeLinkColor }}>Учет абонементов</Link></li>
                      <li><Link to='/feature/tasks/' activeStyle={{ color: activeLinkColor }}>Планировщик задач</Link></li>
                      <li><Link to='/feature/analytics/' activeStyle={{ color: activeLinkColor }}>Аналитика</Link></li>
                      <li><Link to='/feature/wages/' activeStyle={{ color: activeLinkColor }}>Расчет зарплат</Link></li>
                      <li><Link to='/feature/mobile/' activeStyle={{ color: activeLinkColor }}>Мобильная версия</Link></li>
                    </ul>
                    <ul className={styles.list}>
                      <li><Link to={`/feature/${changeableLink('portal')}/`} activeStyle={{ color: activeLinkColor }}>Кабинет клиента</Link></li>
                      <li><Link to='/feature/messengers/' activeStyle={{ color: activeLinkColor }}>Мессенджеры</Link></li>
                      <li><Link to='/feature/iptelephony/' activeStyle={{ color: activeLinkColor }}>IP-телефония</Link></li>
                      <li><Link to='/feature/notifications/' activeStyle={{ color: activeLinkColor }}>Автоуведомления</Link></li>
                      <li><Link to='/feature/onlinecashbox/' activeStyle={{ color: activeLinkColor }}>Онлайн-оплата</Link></li>
                      <li><Link to='/feature/widgets/' activeStyle={{ color: activeLinkColor }}>Виджеты</Link></li>
                    </ul>
                  </div>
                </div>
                : ''
              }
            </div>
            <div className={`${styles.col} ${location ? styles.featureCol : ''}`}>
              <div className={`${styles.wrap} ${styles.featureWrap}`}>
                <ul className={`${styles.list} ${styles.pages_list}`}>
                  {!location ?
                    <li><Link activeStyle={{ color: activeLinkColor }} to="/feature">Возможности</Link></li>
                    : ''
                  }
                  <li><Link activeStyle={{ color: activeLinkColor }} to="/advantages/">Преимущества</Link></li>
                  <li><Link activeStyle={{ color: activeLinkColor }} to="/pricing/">Тарифы</Link></li>
                  <li><Link activeStyle={{ color: activeLinkColor }} to="/franchise/">Для сетей и франшиз</Link></li>
                  <li><Link activeStyle={{ color: activeLinkColor }} to="/partners/">Партнерам</Link></li>
                </ul>
              </div>
              <div className={styles.column} style={lang === "en" ? { flexBasis: "auto" } : null}>
                <ul className={`${styles.list} ${styles.pages_list}`}>
                  {
                    lang === "ru"
                      ? <>
                        <li><Link activeStyle={{ color: activeLinkColor }} to="/clients/">Наши клиенты</Link></li>
                        <li><Link activeStyle={{ color: activeLinkColor }} to="/ourpartners/">Наши партнеры</Link></li>
                        <li><Link activeStyle={{ color: activeLinkColor }} to="/knowledgebase/">База знаний</Link></li>
                        <li><Link activeStyle={{ color: activeLinkColor }} to="/obuchenie-po-crm/">Обучение по CRM</Link></li>
                        <li><Link activeStyle={{ color: activeLinkColor }} to="/obnovleniya-paraplan-crm">Обновления CRM</Link></li>
                        <li><Link activeStyle={{ color: activeLinkColor }} to="/blog/">Блог</Link></li>
                      </>
                      : <>
                        <li>
                          <a href={"#features"}>
                            <Translate id={"menu.features.title"} />
                          </a>
                        </li>
                        <li>
                          <a href={"#pricing"}>
                            <Translate id={"menu.pricing"} />
                          </a>
                        </li>
                      </>
                  }
                </ul>
                <FooterLinks className={styles.links} />
              </div>
            </div>
          </div>
        </div>
      </>
        : null
      }

      <div className={`${styles.column} ${styles.social_list}`}>
        <FooterSocial />
        <a className={styles.tgBtn} href="https://t.me/ParaplanCRMnews" target="_blank">Telegram канал</a>
      </div>
    </div>
  )
}

export default withLanguage(FooterMenuComponent)
