import React from "react"
import { Link } from "gatsby"
import styles from "./header-menu.module.scss"
import HeaderCtaComponent from "./header-cta"
import CallbackOverlayService from "../../../../services/callback-overlay-service"
import HeaderMenuMobileComponent from "./header-menu-mobile"
import Translate from "../../../../i18n/Translate"
import { withLanguage } from "../../../../i18n/with-language"
import HeaderMenuFeatureItem from "./header-menu-feature-item"
import HeaderMenuMoreItem from "./header-menu-more-item"
import { changeableLink, isPage } from "../definition-page"

const getRussianMenu = ({ headerColor, pageClassName, tryNowCaption, name }) => <>
  <li className={styles.menuItem}>
    <span style={(pageClassName === "about") ? { color: "#4C4F5F" } : {}}>
      <Translate id='menu.landings.title' />
    </span>
    <ul className={styles.subMenu}>
      <HeaderMenuFeatureItem headerColor={headerColor} pageClassName={pageClassName} link="/landing/education/" landingName={"education"} />
      <HeaderMenuFeatureItem headerColor={headerColor} pageClassName={pageClassName} link="/landing/sports/" landingName={"sports"} />
      <HeaderMenuFeatureItem headerColor={headerColor} pageClassName={pageClassName} link="/landing/creativeschool/" landingName={"creativeschool"} />
      <HeaderMenuFeatureItem headerColor={headerColor} pageClassName={pageClassName} link="/landing/crm-dlya-muzykalnoj-shkoly/" landingName={"crm-dlya-muzykalnoj-shkoly"} />
      <HeaderMenuFeatureItem headerColor={headerColor} pageClassName={pageClassName} link="/landing/crm-dlja-tvorcheskih-shkol/" landingName={"crm-dlja-tvorcheskih-shkol"} />
      <HeaderMenuFeatureItem headerColor={headerColor} pageClassName={pageClassName} link="/landing/language/" landingName={"language"} />
      <HeaderMenuFeatureItem headerColor={headerColor} pageClassName={pageClassName} link="/landing/crm-dlya-repetitora/" landingName={"crm-dlya-repetitora"} />
      <HeaderMenuFeatureItem headerColor={headerColor} pageClassName={pageClassName} link="/landing/beauty/" landingName={"beautyschool"} />
    </ul>
  </li>
  {isPage() ?
    
    <li className={styles.menuItem}>
      <span style={(pageClassName === "about") ? { color: "#4C4F5F" } : {}}>
        <Translate id='menu.features.title' />
      </span>
      <ul className={styles.subMenu}>
        <HeaderMenuFeatureItem headerColor={headerColor} pageClassName={pageClassName} link="/feature/clients/" featureName={"clients"} />
        <HeaderMenuFeatureItem headerColor={headerColor} pageClassName={pageClassName} link={`/feature/${changeableLink('subscriptions')}/`} featureName={"subscriptions"} />
        <HeaderMenuFeatureItem headerColor={headerColor} pageClassName={pageClassName} link="/feature/tasks/" featureName={"tasks"} />
        <HeaderMenuFeatureItem headerColor={headerColor} pageClassName={pageClassName} link="/feature/analytics/" featureName={"analytics"} />
        <HeaderMenuFeatureItem headerColor={headerColor} pageClassName={pageClassName} link="/feature/wages/" featureName={"wages"} />
        <HeaderMenuFeatureItem headerColor={headerColor} pageClassName={pageClassName} link="/feature/mobile/" featureName={"mobile"} />
        <HeaderMenuFeatureItem headerColor={headerColor} pageClassName={pageClassName} link={`/feature/${changeableLink('portal')}/`} featureName={"portal"} />
        <HeaderMenuFeatureItem headerColor={headerColor} pageClassName={pageClassName} link="/feature/messengers/" featureName={"messengers"} />
        <HeaderMenuFeatureItem headerColor={headerColor} pageClassName={pageClassName} link="/feature/iptelephony/" featureName={"telephony"} />
        <HeaderMenuFeatureItem headerColor={headerColor} pageClassName={pageClassName} link="/feature/notifications/" featureName={"notifications"} />
        <HeaderMenuFeatureItem headerColor={headerColor} pageClassName={pageClassName} link="/feature/onlinecashbox/" featureName={"onlinecashbox"} />
        <HeaderMenuFeatureItem headerColor={headerColor} pageClassName={pageClassName} link="/feature/widgets/" featureName={"widgets"} />
      </ul>
    </li> : ''
  }
  {!isPage() ?
    <li className={styles.menuItem}>
      <Link to="/feature" style={(pageClassName === "about") ? { color: "#4C4F5F" } : {}}>
        <Translate id='menu.featuresindex' />
      </Link>
    </li>
    : ''
  }
  
  <li className={`${styles.menuItem} ${styles.sale}`}>
    <Link to="/pricing/" style={(pageClassName === "about") ? { color: "#4C4F5F" } : {}}>
      <Translate id='menu.pricing' />
    </Link>
  </li>
  <li className={styles.menuItem}>
    <Link to="/franchise/" style={(pageClassName === "about") ? { color: "#4C4F5F" } : {}}>
      <Translate id='menu.franchise' />
    </Link>
  </li>
  <li className={styles.menuItem}>
    <span style={(pageClassName === "about") ? { color: "#4C4F5F" } : {}}>
      <Translate id='menu.more.title' />
    </span>
    <ul className={styles.subMenu}>
      <HeaderMenuMoreItem headerColor={headerColor} pageClassName={pageClassName} moreName={"advantages"} />
      <HeaderMenuMoreItem headerColor={headerColor} pageClassName={pageClassName} moreName={"knowledgebase"} />
      <HeaderMenuMoreItem headerColor={headerColor} pageClassName={pageClassName} moreName={"obuchenie-po-crm"} />
      <HeaderMenuMoreItem headerColor={headerColor} pageClassName={pageClassName} moreName={"obnovleniya-paraplan-crm"} />
      <HeaderMenuMoreItem headerColor={headerColor} pageClassName={pageClassName} moreName={"about"} />
      <HeaderMenuMoreItem headerColor={headerColor} pageClassName={pageClassName} moreName={"blog"} />
      <HeaderMenuMoreItem headerColor={headerColor} pageClassName={pageClassName} moreName={"partners"} />
      <HeaderMenuMoreItem headerColor={headerColor} pageClassName={pageClassName} moreName={"clients"} />
      <li><button className={styles.cta} onClick={() => CallbackOverlayService.open()}>Перезвоните мне</button></li>
    </ul>
  </li>
</>

const getEnglishMenu = () => <>
  <li className={styles.menuItem}>
    <a href="#features">
      <Translate id='menu.features.title' />
    </a>
  </li>
  <li className={styles.menuItem}>
    <a href="#pricing">
      <Translate id='menu.pricing' />
    </a>
  </li>
</>

const HeaderMenuComponent = ({ headerColor, lang, pageClassName }) => (
  <div className={styles.headerMenu}>
    <ul className={styles.menu}>
      {lang === "ru" && getRussianMenu({ headerColor, pageClassName })}
      {lang === "en" && getEnglishMenu(headerColor)}
      {lang === "ru" && <li className={styles.menuItem}>
        <a target='_blank' href='/crm' style={(pageClassName === "about") ? { color: "#4C4F5F" } : {}}>
          <Translate id='menu.signIn' />
        </a>
      </li>}
    </ul>
    <HeaderCtaComponent headerColor={headerColor} pageClassName={pageClassName} />
    <HeaderMenuMobileComponent pageClassName={pageClassName} />
  </div>
)

export default withLanguage(HeaderMenuComponent)
