import React, { useContext, useState } from "react"
import { getModalDescription, getModalTitle } from "./utils"
import SubscribeOverlayService from "../../../services/subscribe-overlay-service"
import BaseOverlayComponent from "../base-overlay"
import SubscribeFormComponent from "../../forms/subscribe"
import Translate from "../../../i18n/Translate"
import { LangContext } from "../../lang-context"

let overlayComponent

const SubscribeOverlayComponent = ({ show }) => {
  const lang = useContext(LangContext);
  const [success, setSuccess] = useState(false)
  const [registrationTokenSentToCustomer, setRegistrationTokenSentToCustomer] = useState(false)


  return (
    <BaseOverlayComponent show={show}
                          title={getModalTitle(success)}
                          description={getModalDescription(success, registrationTokenSentToCustomer)}
                          onClose={() => setSuccess(false)}
                          controller={SubscribeOverlayService}
                          ref={overlay => overlayComponent = overlay}>
      <SubscribeFormComponent onSuccess={() => setSuccess(true)}
                              onCloseRequested={() => overlayComponent.close()}/>
    </BaseOverlayComponent>
  )
}

export default SubscribeOverlayComponent
