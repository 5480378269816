import React from "react"

import SubscribeOverlayService from "../../../services/subscribe-overlay-service"
import styles from "./subscribe.module.scss"
import Translate from "../../../i18n/Translate"
import { LangContext } from "../../lang-context"

export function getModalTitle(success) {
  if (success) {
    return <Translate id={"form.thankYouHeaderCallback"} />
  }

  const { action, block } = SubscribeOverlayService._from || {}
}

export function getModalDescription(success, registrationTokenSentToCustomer) {

  if (!success) {
    return (<div>{htmlStr()}</div>)
  }
  return  <Translate id={success ? "subscribe.thankYouMessage" : "subscribe.formMessage"}/>
}

function htmlStr() {
  return (
    <div className={styles.head}>
      <div className={styles.title}>Скачать чек-лист</div>
      <div className={styles.descr}>
        Введите вашу почту и получите доступ к материалам.
      </div>
    </div>
  )
}
