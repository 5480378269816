import React from "react"
import styles from "./feedback-form.module.scss"
import Input from "../../controls/input"
import Checkbox from "../../controls/checkbox"
import feedbackService from "../../../services/feedback-service"
import feedbackOverlayService from "../../../services/feedback-overlay-service"
import LoaderComponent from "../shared/loader"
import SuccessComponent from "../shared/success"
import TextArea from "../../controls/textarea"
import Translate from "../../../i18n/Translate"
import { ReCaptcha } from "react-recaptcha-google"
import { graphql, useStaticQuery } from "gatsby"
import isMobilePhone from 'validator/es/lib/isMobilePhone';

function useCaptchaToken() {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            captchaToken
          }
        }
      }
    `,
  )
  return site.siteMetadata.captchaToken;
}

// todo refactor to functional component
class FeedbackFormComponent extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      inProgress: false,
      termsAccepted: true,
      name: "",
      phone: "",
      email: "",
      comment: "",
      validation: null,
      showCaptcha: false,
      captcha: null,
    }
  }

  componentDidMount() {
    if (this.captchaElement) {
      this.captchaElement.reset()
    }
  }

  onAgreementChange = (event) => {
    const termsAccepted = event.target.checked
    this.setState({ termsAccepted })
  }

  validatePhoneNumber = (number) => {
    const isValidPhoneNumber = isMobilePhone(number)
    return (isValidPhoneNumber)
  }

  sendForm = async () => {
    const { name, phone, email, inProgress, captcha } = this.state
    if (inProgress) {
      return
    }

    const comment = this.state.comment || feedbackOverlayService.defaultMessage

    if( !this.validatePhoneNumber(phone) ) {
      this.setState({
        validation: {
          fieldErrorCodeList: {
            phone: 'required'
          }
        }
      })
      return
    }

    this.setState({ inProgress: true })
    feedbackService.send(name, phone, email, comment, captcha, this.props.lang)
      .then(validation => this.setState({ validation }))
      .then(() => this.setState({ inProgress: false }))
      .then(() => this.isSuccess ? this.props.onSuccess(this.state.validation["registrationTokenSentToCustomer"]) : null)
      .then(() => this.setState({
        showCaptcha: this.needToShowCaptcha,
        captcha: null,
      }))
  }

  handleFieldChange = (name) => (event) => {
    this.setState({ [name]: event.target.value })
  }

  get isSuccess() {
    const { validation } = this.state
    return validation && validation.success
  }

  get needToShowCaptcha() {
    const { validation } = this.state
    return validation && validation.fieldErrorCodeList && validation.fieldErrorCodeList.captcha
  }

  // Генерирует уникальный elementId для ReCaptcha, чтобы могло рендериться несколько элементов
  generateKey = (pre) => {
    return `${pre}_${new Date().getTime()}`
  }

  onLoadRecaptcha() {
    if (this.captchaElement) {
      this.captchaElement.reset()
    }
  }

  handleChange = recaptchaToken => {
    this.setState({ captcha: recaptchaToken })
  }

  render() {
    const { termsAccepted, validation, inProgress, showCaptcha } = this.state
    const { onCloseRequested, showMessage } = this.props

    const policy = <div className={styles.policy}>
      <Checkbox checked={termsAccepted}
                onChange={this.onAgreementChange}>
        <span><Translate id={"form.disclaimer"}/></span>
      </Checkbox>
    </div>

    return <Translate>
      {
        (translate) => !validation || !validation.success
          ? (
            <>
              <div className={`${styles.form} ${showMessage ? styles.withMessage : ""}`}>
                <Input name={"name"}
                       validation={validation}
                       placeholder={translate("form.name")}
                       value={this.state.name}
                       onChange={this.handleFieldChange("name")}
                       inputClassName={"gtm_send-input"}
                       maxLength={50}/>
                <Input name={"phone"}
                       validation={validation}
                       placeholder={translate("form.phone")}
                       value={this.state.phone}
                       onChange={this.handleFieldChange("phone")}
                       inputClassName={"gtm_send-input"}
                       maxLength={255}/>
                <Input name={"email"}
                       validation={validation}
                       placeholder={translate("form.email")}
                       value={this.state.email}
                       onChange={this.handleFieldChange("email")}
                       inputClassName={"gtm_send-input"}
                       maxLength={50}/>
                {
                  showMessage && <TextArea name={"message"}
                                           validation={validation}
                                           placeholder={translate("form.message")}
                                           value={this.state.comment}
                                           onChange={this.handleFieldChange("comment")}
                                           textAreaClassName={"gtm_send-input"}
                                           rows={4}
                                           maxLength={500}/>
                }
                {
                  showMessage && showCaptcha ? <ReCaptcha ref={(el) => this.captchaElement = el}
                                                          elementID={this.generateKey()}
                                                          hl={translate("form.captchaLanguage")}
                                                          size="normal"
                                                          theme="light"
                                                          onloadCallback={this.onLoadRecaptcha}
                                                          verifyCallback={this.handleChange}
                                                          sitekey={useCaptchaToken()}/>
                    : <div hidden={true}/>
                }
                {showMessage && policy}
                {!showMessage && policy}
                <button disabled={!termsAccepted}
                        className={`${styles.send} gtm_send-btn`}
                        onClick={this.sendForm}>
                  {inProgress ? <LoaderComponent/> : "Получить бонусы"}
                </button>
                {
                  !showMessage && showCaptcha ? <ReCaptcha ref={(el) => this.captchaElement = el}
                                                           elementID={this.generateKey()}
                                                           hl={translate("form.captchaLanguage")}
                                                           size="normal"
                                                           theme="light"
                                                           onloadCallback={this.onLoadRecaptcha}
                                                           verifyCallback={this.handleChange}
                                                           sitekey={useCaptchaToken()}/>
                    : <div hidden={true}/>
                }
              </div>
              <div className={styles.existAccount}>Уже есть аккаунт?&nbsp;<a href="/crm/">Войти</a></div>
            </>)
          : (
            <>
              <SuccessComponent/>
              {
                onCloseRequested
                  ? <div className={styles.closeButton}>
                    <button className={styles.send}
                            onClick={onCloseRequested}>
                      {translate("form.close")}
                    </button>
                  </div>
                  : null
              }
            </>
          )
      }
    </Translate>
  }
}

export default FeedbackFormComponent
