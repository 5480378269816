import React from "react"
import Helmet from "react-helmet"
import FocusTrap from "focus-trap-react"
import styles from "./base-overlay.module.scss"

class BaseOverlayComponent extends React.Component {

  constructor(props) {
    super(props)
    this.state = { closeAnimation: false, success: false }
    this.focusTrapOptions = { returnFocusOnDeactivate: false }
    this.controller = props.controller
  }

  handleOutsideClick = (event) => {
    if (this.modal) {
      if (!this.modal.contains(event.target)) {
        this.close()
        document.removeEventListener("click", this.handleOutsideClick, false)
      }
    }
  }

  close = () => {
    if (this.state.closeAnimation) {
      return
    }

    this.setState({ closeAnimation: true })
    setTimeout(() => {
      this.setState({ closeAnimation: false })
      this.controller.close()
    }, 200)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!this.props.show && nextProps.show) {
      this.onModalOpen()
    } else if (this.props.show && !nextProps.show) {
      this.onModalClose()
    }
  }

  onModalOpen() {
    this.escListener = (e) => e.keyCode === 27 ? this.close() : null
    window.addEventListener("keydown", this.escListener)
    document.addEventListener("mousedown", this.handleOutsideClick, false)
  }

  onModalClose() {
    window.removeEventListener("keydown", this.escListener)
    document.removeEventListener("mousedown", this.handleOutsideClick, false)
    this.props.onClose && this.props.onClose()
  }

  render() {
    if (!this.props.show) {
      return null
    }

    const { closeAnimation } = this.state
    const { choosePlan } = this.props;

    return <>
      <Helmet htmlAttributes={{ class: "modal-open" }}/>
      <FocusTrap focusTrapOptions={this.focusTrapOptions}>
        <div className={`${styles.backdrop} ${closeAnimation ? styles.onBeforeClose : ""}`}>
          <div className={`${styles.modal} ${choosePlan ? styles.modalChoosePlan: ''}`}
               ref={node => this.modal = node}>
            <div className={styles.control}>
              <button className={styles.close}
                   onClick={this.close}>
                <img alt=''
                     className={styles.desktop}
                     src={require(choosePlan ? "../../images/close_blue.svg":"../../images/close-purple.svg")}/>
                <img alt=''
                     className={styles.mobile}
                     src={require(choosePlan ? "../../images/close_blue.svg":"../../images/close-purple-mobile.svg")}/>
              </button>
            </div>
            <div className={styles.title}>
              {this.props.title}
            </div>
            <div className={styles.description}>
              {this.props.description}
            </div>
            {this.props.children}
          </div>
        </div>
      </FocusTrap>
    </>
  }
}

export default BaseOverlayComponent
