import React from "react"
import Link from "gatsby"

export const LANGUAGES = ["ru", "en", "de"]

export const MESSAGES = {
  menu: {
    home: ["Главная", "Home", "Home"],
    landings: {
      title: ["Направления", "Directions", "Richtungen"],
      education: ["Обучение", "Education", "Ausbildung"],
      sports: ["Спорт ", "Sports", "Sport"],
      creativeschool: ["Танцы ", "Dancing", "Tanzen"],
      "crm-dlya-muzykalnoj-shkoly": ["Музыка ", "Music", "Musik"],
      "crm-dlja-tvorcheskih-shkol": ["Творчество ", "Creation", "Schaffung"],
      language: ["Язык ", "Language", "Sprache"],
      "crm-dlya-repetitora": ["Репетиторство  ", "Repetitor", "Repertoire"],
      beautyschool: ["Beauty бизнес ", "Beauty saloon", "Schönheitssalon"],
    },
    features: {
      title: ["Возможности", "Features", "Funktionen"],
      clients: ["Учет клиентов", "CRM", "CRM"],
      subscriptions: ["Учет абонементов", "Billing management", "Anwesenheitskontrolle"],
      tasks: ["Планировщик задач", "Task management", "Task Manager"],
      analytics: ["Аналитика", "Reporting", "Statistiken"],
      wages: ["Расчет зарплат", "Staff payroll", "Finanzbuchhaltung"],
      mobile: ["Мобильная версия", "Mobile access", "Mobile App"],
      portal: ["Кабинет клиента", "Client Cabinet", "Kundenkabinett"],
      messengers: ["Мессенджеры", "Messengers", "Boten"],
      telephony: ["IP-телефония", "IP telephony", "IP-Telefonie"],
      notifications: ["Автоуведомления", "Auto notifications", "Automatische Benachrichtigungen"],
      onlinecashbox: ["Онлайн-оплата", "Online cash desk", "Online-Kasse"],
      widgets: ["Виджеты", "Widgets", "Widgets"],
    },
    featuresindex: ["Возможности", "Features", "Funktionen"],
    pricing: ["Тарифы", "Pricing", "Preise"],
    knowledgebase: ["База знаний", "Knowledgebase", "Knowledgebase"],
    about: ["О нас", "About", "Über"],
    clients: ["Наши клиенты", "Our clients", "Unsere Kunden"],
    partners: ["Партнерам", "Partners", "Partnern"],
    franchise: ["Франшизам", "Enterprise", "Enterprise"],
    blog: ["Блог", "Blog", "Blog"],
    more: {
      title: ["Ещё", "Features", "Funktionen"],
      advantages: ["Преимущества", "Advantages", "Vorteile"],
      knowledgebase: ["База знаний", "Knowledgebase", "Knowledgebase"],
      "obuchenie-po-crm": ["Обучение по CRM", "CRM training", "CRM-Schulung"],
      "obnovleniya-paraplan-crm": ["Обновления CRM", "CRM updates", "Aktualisierungen"],
      about: ["О нас", "Billing management", "Anwesenheitskontrolle"],
      clients: ["Наши клиенты", "Our clients", "Unsere Kunden"],
      blog: ["Блог", "Reporting", "Statistiken"],
      partners: ["Партнерам", "Partners", "Partnern"],
    },
    signIn: ["Войти", "Sign In", "Einloggen"],
    mobileCallback: ["Перезвоните мне", "call me back", "Ruf mich zurück"],
    cta: ["Попробовать бесплатно", "Try for free", "Kostenlos anmelden"],
    ctaTry: ["Попробовать", "Try for free", "Kostenlos anmelden"],
  },
  form: {
    captchaLanguage: ["ru", "en", "de"],
    name: ["Имя", "Your name", "Name"],
    phone: ["Телефон *", "Your phone", "Telefonnummer"],
    email: ["Email", "Your e-mail", "E-mail"],
    message: ["Комментарий", "Your message", "Ihre Nachricht"],
    yourQuestions: ["Ваш вопрос", "Your question", "Ihre Frage"],
    disclaimer: [
      <>Я даю согласие на {` `}<a href={"/policy"} target={"_blank"}>обработку персональных данных</a>{` `}</>,
      <>I have read and accept the <a href={"/en/policy"} target={"_blank"}>privacy policy</a></>,
      <>Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner Anfrage erhoben und
        verarbeitet werden.</>,
    ],
    send: ["Отправить", "Send", "Absenden"],
    register: ["Зарегистрироваться", "Register", "Registrieren"],
    close: ["Закрыть", "Close", "Schließen"],
    orderCall: ["Заказать звонок", "Order a call", "Anruf bestellen"],
    subscribe: ["Получить доступ", "Get access", "Kostenlos anmelden"],
    formHeader: [<>Добро пожаловать в Параплан!</>, <>Are you ready to take your business to the
      next level?</>, <>Nehmen Sie direkt Kontakt zu uns auf!</>],
    formMessage: [<>Заполните форму и получите скидку до 25% и подарочные дни</>,
    <>Try Paraplan today. We're ready to answer any questions you might have, just get in touch below.</>,
    <>Sie möchten mehr wissen? Gerne kontaktieren wir Sie.</>,
    ],
    thankYouHeader: ["Спасибо за заявку!", "Thank you for your request!", "Vielen Dank!"],
    thankYouMessage: ["Ваш email адрес был зарегистрирован ранее. Специалист нашей команды свяжется с вами в ближайшее время.", "We will get in touch as soon as possible.", "Wir werden Sie so schnell wie möglich kontaktieren"],
    thankYouMessageWithEmail: ["Пожалуйста, проверьте свою почту. Мы выслали доступ к системе на Ваш электронный адрес. Если Вы не нашли письмо, проверьте папку «Спам».", "You have access to the system. Please, check the mail.", "Wir werden Sie so schnell wie möglich kontaktieren"],
    contactUs: ["Откройте возможности Параплан!", "Contact us", "Kontaktieren Sie uns"],
    pricingPlan: [
      (name) => `Тариф ${name.toLowerCase()}`,
      (name) => `${name} plan`,
      (name) => `${name} plan`,
    ],
    formMessageCallback: [<>Заполните форму, и наш менеджер <br className="br-mobile" />свяжется с вами <br className="br-desktop" /> в будни с 10:00 до <br className="br-mobile" />18:00 по Мск.</>,],
    thankYouHeaderCallback: ["Спасибо за обращение!", "", ""],
    thankYouMessageCallback: ["Мы получили Ваш запрос на обратный звонок. Наш специалист свяжется с Вами в будни с 10 до 18 часов по Мск.", "", ""],
    thankYouMessagePresentation: ["Мы получили Ваш запрос на презентацию. Наш специалист свяжется с Вами в будни с 10 до 18 часов по Мск.", "", ""],
    thankYouMessageBecomepartner: ["Мы получили Вашу заявку. Наш менеджер свяжется с вами для обсуждения условий сотрудничества.", "", ""],
  },
  subscribe: {
    thankYouMessage: ["Спасибо! Чек-лист выслан на Вашу электронную почту.", "Thank you! The checklist has been sent to your email.", "Thank you! The checklist has been sent to your email."],
    formMessage: [<>Введите вашу почту и получите доступ к материалам.</>, <>Enter your email address to get the
      checklist</>, <>Enter your email address to get the
        checklist</>],
  },
  field: {
    required: ["Заполните поле", "This field is required", "Das ist ein Pflichtfeld"],
    notValid: ["Неправильный email адрес", "Invalid email address", "Ungültige Email-Adresse"],
    tooLong: ["Слишком длинный email адрес", "Email address too long", "Ungültige Email-Adresse"],
  },
  detailPricing: {
    monthlyTitle: ["На месяц", "Monthly", "1 Monat"],
    halfYearTitle: ["6 месяцев - выгода 10%", "Monthly", "6 Monate"],
    quarterlyTitle: ["3 месяца – выгода 5%", "Quarterly – SAVE 5%", "3 Monate"],
    yearlyTitle: ["1 год – выгода 15%", "Yearly – SAVE 15%", "12 Monate"],
  },
  pricing: {
    monthlyTitle: ["На месяц", "Monthly", "1 Monat"],
    quarterlyTitle: ["3 месяца – выгода 10%", "Quarterly – SAVE 10%", "3 Monate"],
    yearlyTitle: ["1 год – выгода 15%", "Yearly – SAVE 15%", "12 Monate"],
  },
  header: {
    freeAccessLabel: ["Бесплатное лето", "Free Summer", "Freier Sommer"] // Удалить после окончания акции бесплатное лето
    //freeAccessLabel: ["Бесплатный доступ 10 дней", "Get Your 10-Day Free Trial Now", "10 Tage kostenlos und unverbindlich"] 
  },
  footer: {
    support: ["Служба техподдержки:", "Contact our support team:", "Unser Support Team:"],
    privacyPolicy: [
      (styles) => <>Политика{` `}
        <span className={styles.privacyPolicyShort}>конфиденциал</span>
        <span className={styles.privacyPolicyFull}>конфиденциальности</span>
      </>,
      () => "Privacy policy",
      () => "Rechtliches",
    ],
  },
}
